import React from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getPathFromSlug } from '../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, navigate } from 'gatsby';

import {
    layout,
    backgroundImage,
    leaf1,
    leaf2,
    bird1,
    bird2,
    container,
    title,
    paragraph,
    labelsWrapper,
    returnButton,
} from './label-thanks-you-page.module.scss';

import { isBrowser } from '../utils/is-browser';
import { ICreateLabelValues } from '../models/create-form.model';
import { TCommonTemplateData } from '../models/common-template-data.model';

import Button from '../components/atoms/button';
import MainLayout from '../layouts/main-layout';
import DownloadableLabel from '../components/molecules/downloadable-label';

const leafCircleSrc = '../assets/images/leaf-circle.png';
const bird1Src = '../assets/images/bird-1.png';
const bird2Src = '../assets/images/bird-2.png';

interface ILabelThanksYouPage {
    data: TCommonTemplateData;
}

const LabelThanksYouPage = ({ data }: ILabelThanksYouPage) => {
    const { page } = data;
    const { t, language } = useI18next();

    const sendLabels = getSendLabel();

    if (!sendLabels.length) {
        if (isBrowser()) {
            navigate(getPathFromSlug(`/soplica-weselna/`, language));
        }
    }

    return (
        <MainLayout className={layout} includeHeaderPadding={true} page={page}>
            <StaticImage
                className={`${backgroundImage} ${leaf1}`}
                src={leafCircleSrc}
                alt={t('label.thank.you.page.background1.alt')}
                placeholder="blurred"
            />
            <StaticImage
                className={`${backgroundImage} ${leaf2}`}
                src={leafCircleSrc}
                alt={t('label.thank.you.page.background2.alt')}
                placeholder="blurred"
            />
            <StaticImage
                className={`${backgroundImage} ${bird1}`}
                src={bird1Src}
                alt={t('label.thank.you.page.background3.alt')}
                placeholder="blurred"
            />
            <StaticImage
                className={`${backgroundImage} ${bird2}`}
                src={bird2Src}
                alt={t('label.thank.you.page.background4.alt')}
                placeholder="blurred"
            />
            <div className={container}>
                <h1 className={title}>
                    <b>{t('label.thank.you.page.title1')}</b>
                    <br />
                    {t('label.thank.you.page.title2')}
                </h1>
                <p className={paragraph}>
                    <b>{t('label.thank.you.page.paragraph1')}</b>
                    <br />
                    <br />
                    {t('label.thank.you.page.paragraph2')}
                    <br />
                    {t('label.thank.you.page.paragraph3')}
                    <br />
                    <b>{t('label.thank.you.page.paragraph4')}</b>
                </p>
                <div className={labelsWrapper}>
                    {sendLabels.map((label: ICreateLabelValues) => (
                        <DownloadableLabel key={`created-label-${label.id}`} label={label} />
                    ))}
                </div>
                <Button as={'link'} to={'/'} className={returnButton}>
                    {t('label.thank.you.page.homepage')}
                </Button>
            </div>
        </MainLayout>
    );
};

function getSendLabel() {
    if (!isBrowser()) {
        return [];
    }

    return JSON.parse(window.sessionStorage.getItem('sendLabels') || '[]');
}

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default LabelThanksYouPage;
