import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { buttonIcon, downloadButton, labelWrapper } from './downloadable-label.module.scss';
import { ICreateLabelValues } from '../../models/create-form.model';
import DownloadIcon from '../../assets/images/svg/download-02.svg';

import Button from '../atoms/button';

export interface IDownloadableLabelProps {
    label: ICreateLabelValues;
}

export default function DownloadableLabel({ label }: IDownloadableLabelProps) {
    const { t } = useI18next();

    if (!label.image) return null;

    return (
        <div className={labelWrapper}>
            <img
                src={label.image}
                alt={`${t('label.thank.you.page.created.label.alt')} ${label.firstLine} ${
                    label.secondLine
                }`}
            />
            <Button
                as={'externalLink'}
                to={label.image.replace('image/png', 'application/octet-stream')}
                variant={'text'}
                className={downloadButton}
                download={`${t('label.thank.you.page.download.filename')}.png`}
            >
                <DownloadIcon className={buttonIcon} />
                {t('Pobierz etykietę')}
            </Button>
        </div>
    );
}
