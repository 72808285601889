// extracted by mini-css-extract-plugin
export var backgroundAnimation = "label-thanks-you-page-module--backgroundAnimation--116b1";
export var backgroundImage = "label-thanks-you-page-module--background-image--19105";
export var bird1 = "label-thanks-you-page-module--bird-1--97e0d";
export var bird2 = "label-thanks-you-page-module--bird-2--600dc";
export var container = "label-thanks-you-page-module--container--4a6d2";
export var errorBlink = "label-thanks-you-page-module--error-blink--ac861";
export var labelsWrapper = "label-thanks-you-page-module--labels-wrapper--2ce74";
export var layout = "label-thanks-you-page-module--layout--98860";
export var leaf1 = "label-thanks-you-page-module--leaf-1--4ee5a";
export var leaf2 = "label-thanks-you-page-module--leaf-2--6991c";
export var paragraph = "label-thanks-you-page-module--paragraph--8d27b";
export var returnButton = "label-thanks-you-page-module--return-button--2da41";
export var title = "label-thanks-you-page-module--title--6a3e4";